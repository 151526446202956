import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Federal laws | How revenue works",
  "includeToc": true,
  "description": "Laws and regulations that govern natural resource extraction on federal land and offshore areas, and Native American land.",
  "tags": ["How revenue works", "Federal laws", "Fiscal regime", "Fees and fines", "Regulations"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Table = makeShortcode("Table");
const TableHead = makeShortcode("TableHead");
const TableRow = makeShortcode("TableRow");
const TableCell = makeShortcode("TableCell");
const TableBody = makeShortcode("TableBody");
const GlossaryTerm = makeShortcode("GlossaryTerm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/how-revenue-works/"
      }}>{`How revenue works`}</a>{` /`}</p>

    <h1 {...{
      "id": "Federal-laws",
      "style": {
        "position": "relative"
      }
    }}>{`Federal laws`}</h1>
    <p>{`There are many laws that govern natural resource extraction on federal lands.`}</p>
    <h2 {...{
      "id": "Fiscal-regime",
      "style": {
        "position": "relative"
      }
    }}>{`Fiscal regime`}</h2>
    <p>{`The laws in this section provide the backbone of the fiscal regime for the extractive industries.`}</p>
    <Table aria-label="Federal laws for the extractives industries" size="small" mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">
        Law name and code
      </TableCell>
      <TableCell mdxType="TableCell">
        Description
      </TableCell>
      <TableCell mdxType="TableCell">
        Relevant lands
      </TableCell>
      <TableCell mdxType="TableCell">
        Relevant natural resources
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/USCODE-2011-title30/pdf/USCODE-2011-title30-chap2-sec22.pdf">
          The General Mining Law of 1872 as Amended (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" />
        </a>
        <br></br>
        (30 USC § 29 et seq. and 43 CFR 3860)
      </TableCell>
      <TableCell mdxType="TableCell">Provides the right to transfer federal lands and natural resources to private ownership.  This right is a mineral patent. Since October 1, 1994, Congress has imposed a budget moratorium on any new mineral patent applications. </TableCell>
      <TableCell mdxType="TableCell">Federal onshore (public domain)</TableCell>
      <TableCell mdxType="TableCell">Locatable hardrock minerals (e.g., gold, silver, and copper)</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/USCODE-2011-title25/pdf/USCODE-2011-title25-chap12-sec396.pdf">
        Leasing of Allotted Lands for Mining Purposes Act of 1909 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" />
        </a>
        <br></br>
        (25 USC § 396 et seq. and 25 CFR 212)
      </TableCell>
      <TableCell mdxType="TableCell">Allows leasing of lands allotted to Indians for mining purposes. Leases can extend up to ten years as determined by the Secretary of the Interior. Excludes Indian lands allotted to members of the Five Civilized Tribes and Osage.</TableCell>
      <TableCell mdxType="TableCell">Indian (allotted)</TableCell>
      <TableCell mdxType="TableCell">Not specified</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">
      <a href="https://www.govinfo.gov/content/pkg/USCODE-2011-title30/html/USCODE-2011-title30-chap3A.htm">Mineral Leasing Act of 1920 as Amended <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a>
        <br></br>
        (30 USC 181 et seq.)
      </TableCell>
      <TableCell mdxType="TableCell">Creates a system of leasing mineral resources on federal lands for extraction.  Also grants BLM authority to administer mineral leasing.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore (public domain)</TableCell>
      <TableCell mdxType="TableCell">Coal, oil, gas, oil or gas shale, sodium, potassium, phosphate, sulfur, and gilsonite</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/USCODE-2009-title25/html/USCODE-2009-title25-chap12.htm">Indian Mineral Leasing Act of 1938 <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a>
        <br></br>
        (25 USC § 396a et seq.)</TableCell>
      <TableCell mdxType="TableCell">Opens unallotted lands within any Indian reservation for leasing for mining purposes. This is by authority of the tribal council and approval of the Secretary of the Interior.</TableCell>
      <TableCell mdxType="TableCell">Indian (tribal)</TableCell>
      <TableCell mdxType="TableCell">Not specified</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="https://www.fs.usda.gov/sites/default/files/media_wysiwyg/30usc351mineralleasingactforacquiredlands1947.pdf">Mineral Leasing Act for Acquired Lands of 1947 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a>
        <br></br>(30 USC § 351 et seq. and 43 CFR 3420)</TableCell>
      <TableCell mdxType="TableCell">Extends the Mineral Leasing Act of 1920. Extends authority of the Secretary of the Interior to govern mineral leasing on federal acquired lands.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore (acquired)</TableCell>
      <TableCell mdxType="TableCell">Coal, oil, gas, oil or gas shale, sodium, potassium, phosphate, sulfur, and gilsonite</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="https://ecfr.federalregister.gov/current/title-43/subtitle-B/chapter-II/subchapter-C/part-3600">Mineral Materials Act of 1947 <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a>
        <br></br>(30 USC § 601 et seq.)</TableCell>
      <TableCell mdxType="TableCell">Also known as the Common Varieties Act. Regulates the sale and permitting of the most common hardrock minerals in place of the General Mining Law of 1872.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore</TableCell>
      <TableCell mdxType="TableCell">Common hardrock minerals (e.g., sand, gravel, stone, pumice, cinders)</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="https://www.boem.gov/sites/default/files/uploadedFiles/BOEM/Oil_and_Gas_Energy_Program/Leasing/Outer_Continental_Shelf/Lands_Act_History/submerged.pdf">Submerged Lands Act of 1953 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(43 USC § 1301 et seq.)</TableCell>
      <TableCell mdxType="TableCell">Recognizes states’ rights to the submerged navigable lands within their boundaries. These include things like lakes and rivers. Also recognizes states' rights to the marine waters within three geographical miles from the coastline.</TableCell>
      <TableCell mdxType="TableCell">State offshore</TableCell>
      <TableCell mdxType="TableCell">All natural resources</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/USCODE-2010-title43/html/USCODE-2010-title43-chap29-subchapIII.htm">Outer Continental Shelf Lands Act of 1953 as Amended <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(43 USC § 1331 et seq.)</TableCell>
      <TableCell mdxType="TableCell">Makes the Secretary of the Interior responsible for the administration of mineral exploration and development of the Outer Continental Shelf.  Activities are subject to environmental safeguards. Requires fair market value for mineral leasing.</TableCell>
      <TableCell mdxType="TableCell">Outer Continental Shelf</TableCell>
      <TableCell mdxType="TableCell">Oil, gas, and other minerals</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/STATUTE-84/pdf/STATUTE-84-Pg1566.pdf">Geothermal Steam Act of 1970 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(30 USC § 1001 et seq.)</TableCell>
      <TableCell mdxType="TableCell">Allows leasing of land for geothermal resources development. This excludes lands prohibited from leasing by the Mineral Leasing Act of 1920.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore</TableCell>
      <TableCell mdxType="TableCell">Geothermal</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/USCODE-2011-title30/pdf/USCODE-2011-title30-chap2-sec21a.pdf">Mining and Minerals Policy Act of 1970 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(30 USC § 21a et seq.)	</TableCell>
      <TableCell mdxType="TableCell">Amends the Mining Act of 1920. Establishes the national interest to develop a mining industry. Also addresses adverse environmental impacts.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore</TableCell>
      <TableCell mdxType="TableCell">All natural resources</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/STATUTE-90/pdf/STATUTE-90-Pg1083.pdf">Federal Coal Leasing Amendments Act (FCLAA) of 1976 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(90 STAT 1083)</TableCell>
      <TableCell mdxType="TableCell">Amends Section 2 of the Mineral Leasing Act of 1920. Requires competitive leasing for all public lands available for coal leasing. Allows the government to only accept lease bids equal to or greater than fair market value. Consolidates leasing into logical mining units. Requires lease holders to operate continually and other measures.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore</TableCell>
      <TableCell mdxType="TableCell">Coal</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/STATUTE-91/pdf/STATUTE-91-Pg445.pdf">Surface Mining Control and Reclamation Act (SMCRA) of 1977 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(30 USC § 1201 et seq.)</TableCell>
      <TableCell mdxType="TableCell">Creates the Office of Surface Mining, Reclamation, and Enforcement (OSMRE). The program balances the need for domestic coal production while protecting the environment. After current mining operations, coal mine owners are required to post bonds as insurance for reclaiming the land. They are also required to pay into the Abandoned Mine Reclamation Fund. This fund is intended to address mines abandoned prior to 1977.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore</TableCell>
      <TableCell mdxType="TableCell">Coal</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="https://uscode.house.gov/view.xhtml?path=/prelim@title30/chapter29&edition=prelim">Federal Oil and Gas Royalty Management Act (FOGRMA) of 1982 <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(30 USC § 1701 et seq.)</TableCell>
      <TableCell mdxType="TableCell">Grants the Secretary of the Interior authority to manage and collect oil and gas royalties from leases on federal and Indian lands.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore, Indian, and Outer Continental Shelf</TableCell>
    <TableCell mdxType="TableCell">Oil and gas</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/STATUTE-96/pdf/STATUTE-96-Pg1938.pdf">Indian Mineral Development Act of 1982 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(25 USC §§ 2101–2108 et seq.)</TableCell>
      <TableCell mdxType="TableCell">Gives Indian tribes flexibility with the use and sale of mineral resources. Creates opportunities to enter into business agreements with the mineral industry.</TableCell>
      <TableCell mdxType="TableCell">Indian (tribal)</TableCell>
      <TableCell mdxType="TableCell">Oil and gas, coal, geothermal, and other mineral resources</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/USCODE-2011-title30/pdf/USCODE-2011-title30.pdf">Federal Onshore Oil and Gas Leasing Reform Act (FOOGLRA) of 1987 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(30 USC § 181 et seq.)</TableCell>
      <TableCell mdxType="TableCell">Amendment to the Mineral Leasing Act of 1920. Gives the US Forest Service the authority to offer leases for oil and gas on National Forest System lands. They must meet environmental and other land-use regulations. BLM administers most leasing on these lands.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore</TableCell>
      <TableCell mdxType="TableCell">Oil and gas</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="https://www.onrr.gov/references/statutes/fogrma-amendments">1996 Amendments to FOGRMA <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(Pub. L. 104-185)</TableCell>
      <TableCell mdxType="TableCell">Revisions to the Federal Oil & Gas Royalty Management Act (FOGRMA).</TableCell>
      <TableCell mdxType="TableCell">Federal onshore, Indian, and Outer Continental Shelf</TableCell>
    <TableCell mdxType="TableCell">Oil and gas</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
    <TableCell mdxType="TableCell"><a href="https://www.congress.gov/104/plaws/publ185/PLAW-104publ185.pdf">Federal Oil and Gas Royalty Simplification and Fairness Act (RSFA) of 1996 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(30 USC § 1701 et seq.)</TableCell>
    <TableCell mdxType="TableCell">Improves royalty management from federal and Outer Continental Shelf oil and gas leases.</TableCell>
    <TableCell mdxType="TableCell">Federal onshore and Outer Continental Shelf</TableCell>
    <TableCell mdxType="TableCell">Oil and gas</TableCell>
  </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/BILLS-109hr6enr/pdf/BILLS-109hr6enr.pdf">Energy Policy Act (EPAct) of 2005 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(42 USC § 13201 et seq.)</TableCell>
      <TableCell mdxType="TableCell">Addresses energy production in the United States. This includes the production, transportation, and transmission of energy on the Outer Continental Shelf from sources other than oil and gas (e.g., wind energy). Includes incentives for oil and gas development and provisions to access oil and gas resources on federal lands.</TableCell>
      <TableCell mdxType="TableCell">Federal onshore and Outer Continental Shelf</TableCell>
      <TableCell mdxType="TableCell">Oil, gas, coal, wind, solar, hydropower, and geothermal</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"><a href="/how-revenue-works/gomesa">Gulf of Mexico Energy Security Act (GOMESA) of 2006 (Gulf of America) </a><br></br>(120 Stat. 2922)</TableCell>
      <TableCell mdxType="TableCell">Opens 8.3 million acres in the Gulf of America for oil and gas leasing. Shares leasing revenue with Gulf of America producing states and the Land and Water Conservation Fund. Bans oil and gas leasing within 125 miles off the Florida coastline in the Eastern Planning Area and a portion of the Central Planning Area until 2022.</TableCell>
      <TableCell mdxType="TableCell">Outer Continental Shelf</TableCell>
      <TableCell mdxType="TableCell">Oil and gas</TableCell>
    </TableRow>
  </TableBody>
    </Table>
    <h2 {...{
      "id": "Fees-and-fines-for-extractive-industries-companies",
      "style": {
        "position": "relative"
      }
    }}>{`Fees and fines for extractive industries companies`}</h2>
    <p>{`There are other laws governing natural resources and extractive companies’ operations. Some of these laws require companies to pay fees. Violating some of these laws can also result in companies paying fines.`}</p>
    <Table aria-label="Federal laws for the extractives industries" size="small" mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">
        Law name and code
      </TableCell>
      <TableCell mdxType="TableCell">
        Description
      </TableCell>
      <TableCell mdxType="TableCell">
        Relevant lands
      </TableCell>
      <TableCell mdxType="TableCell">
        Relevant natural resources
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
  <TableRow mdxType="TableRow">
    <TableCell mdxType="TableCell"><a href="https://www.blm.gov/sites/blm.gov/files/AboutUs_LawsandRegs_FLPMA.pdf">Federal Land Policy and Management Act (FLPMA) of 1976 as Amended (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(43 USC § 1701 et seq.)</TableCell>
    <TableCell mdxType="TableCell">Requires BLM to use a land use planning framework to administer federal lands. The framework includes the following: no unnecessary or undue degradation; multiple-use, sustained yield considerations for present and future generations; and public planning. Requires receipt of fair market value for use of federal lands and resources.</TableCell>
    <TableCell mdxType="TableCell">Federal onshore and Indian</TableCell>
  <TableCell mdxType="TableCell">All natural resources</TableCell>
  </TableRow>
  <TableRow mdxType="TableRow">
    <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/USCODE-2008-title42/pdf/USCODE-2008-title42-chap85.pdf">Clean Air Act (CAA) of 1970 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(42 USC § 7401 et seq.)</TableCell>
    <TableCell mdxType="TableCell">Outlines steps to decrease air pollution. Applies to federal agencies, state and local governments, and industry. Oil and gas wells are exempt from legal aggregation. This means the emissions from small sites that are connected, in close proximity, or under shared ownership, are added together. They are regulated as “stationary sources” if they emit or could emit 100 tons per year of a pollutant.
    </TableCell>
    <TableCell mdxType="TableCell">All lands</TableCell>
  <TableCell mdxType="TableCell">All natural resources, except when oil and gas are exempted</TableCell>
  </TableRow>
  <TableRow mdxType="TableRow">
    <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/USCODE-2010-title33/pdf/USCODE-2010-title33-chap26.pdf">Clean Water Act (CWA) of 1977 (PDF) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(33 USC § 1251 et seq.)</TableCell>
    <TableCell mdxType="TableCell">Establishes regulations to protect water quality and track discharges of pollution into bodies of water. Does not require permits for uncontaminated storm water discharges from the following oil and gas activities: exploration, production, processing or treatment operations, transmission, and drill site preparation.</TableCell>
    <TableCell mdxType="TableCell">All lands</TableCell>
  <TableCell mdxType="TableCell">All natural resources, except when oil and gas are exempted</TableCell>
  </TableRow>
  <TableRow mdxType="TableRow">
    <TableCell mdxType="TableCell"><a href="http://www.gpo.gov/fdsys/pkg/USCODE-2008-title42/pdf/USCODE-2008-title42-chap85.pdf">Safe Drinking Water Act (SDWA) of 1974 (42 USC §§ 300f–300j et seq.) (PDF)<OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a></TableCell>
    <TableCell mdxType="TableCell">Protects public health by regulating the nation’s public drinking water supply and its sources. As of the 2005 Energy Policy Act, hydraulic fracturing fluids are exempt from underground injection control permits unless diesel fuel is used in the extraction process.</TableCell>
    <TableCell mdxType="TableCell">All lands</TableCell>
  <TableCell mdxType="TableCell">All natural resources, except when oil and gas are exempted</TableCell>
  </TableRow>
  <TableRow mdxType="TableRow">
    <TableCell mdxType="TableCell"><a href="https://www.epa.gov/laws-regulations/summary-comprehensive-environmental-response-compensation-and-liability-act">Comprehensive Environmental Response, Compensation, and Liability Act (CERCLA) of 1980 <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(42 USC §§ 9601–9675 et seq.)</TableCell>
    <TableCell mdxType="TableCell">Provides a federal ‘superfund’ to clean up uncontrolled or abandoned hazardous-waste sites. Also covers clean up from accidents, spills, and other emergency releases of pollutants and contaminants into the environment. Gives EPA the power to seek out parties responsible for any release and assure their cooperation in the cleanup.</TableCell>
    <TableCell mdxType="TableCell">All lands</TableCell>
  <TableCell mdxType="TableCell">All natural resources, except when oil and gas are exempted</TableCell>
  </TableRow>
  <TableRow mdxType="TableRow">
    <TableCell mdxType="TableCell"><a href="https://www.fisheries.noaa.gov/topic/laws-policies#endangered-species-act">Endangered Species Act (ESA) of 1973 <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(16 USC § 1531 et seq.)</TableCell>
    <TableCell mdxType="TableCell">Protects and recovers imperiled species and the ecosystems upon which they depend.</TableCell>
    <TableCell mdxType="TableCell">All lands</TableCell>
  <TableCell mdxType="TableCell">All natural resources</TableCell>
  </TableRow>
  <TableRow mdxType="TableRow">
    <TableCell mdxType="TableCell"><a href="https://www.fws.gov/international/laws-treaties-agreements/us-conservation-laws/marine-mammal-protection-act.html">Marine Mammal Protection Act of 1972 as Amended <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br></br>(16 USC § 1361 et seq.)</TableCell>
    <TableCell mdxType="TableCell">Prohibits, with certain exceptions, the "take" of marine mammals in US waters and by US citizens on the high seas. Also prohibits, with certain exceptions, the importation of marine mammals and marine mammal products into the United States.</TableCell>
    <TableCell mdxType="TableCell">All lands</TableCell>
  <TableCell mdxType="TableCell">All natural resources, except when oil and gas are exempted</TableCell>
  </TableRow>
  </TableBody>
    </Table>
    <h2 {...{
      "id": "Other-laws",
      "style": {
        "position": "relative"
      }
    }}>{`Other laws`}</h2>
    <p>{`There are many other laws with which extractive industries companies must comply. Several federal agencies’ websites contain more comprehensive lists of laws they enforce:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.boem.gov/Regulations/BOEM-Governing-Statutes.aspx"
        }}>{`Bureau of Ocean Energy Management `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` (BOEM)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.bsee.gov/Regulations-and-Guidance/BSEE-Governing-Statutes/"
        }}>{`Bureau of Safety and Environmental Enforcement `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` (BSEE)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.blm.gov/about/laws-and-regulations"
        }}>{`Bureau of Land Management `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` (BLM)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.epa.gov/laws-regulations/laws-and-executive-orders#majorlaws"
        }}>{`Environmental Protection Agency `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` (EPA)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.osmre.gov/laws-and-regulations"
        }}>{`Office of Surface Mining Reclamation and Enforcement `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` (OSMRE)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.fisheries.noaa.gov/topic/laws-policies"
        }}>{`National Oceanic and Atmospheric Administration `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` (NOAA)`}</li>
    </ul>
    <h2 {...{
      "id": "Regulations",
      "style": {
        "position": "relative"
      }
    }}>{`Regulations`}</h2>
    <p>{`Federal agencies implement laws by developing and enforcing regulations. The regulations below relate to natural resource extraction on federal and Indian lands.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.gpo.gov/fdsys/search/pagedetails.action?collectionCode=CFR&searchPath=Title+25%2FChapter+I%2FSubchapter+I&granuleId=&packageId=CFR-2008-title25-vol1&oldPath=Title+25%2FChapter+I%2FSubchapter+A&fromPageDetails=true&collapse=true&ycord=435"
        }}>{`Title 25 `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` in the Code of Federal Regulations relates to sovereign Indian nations. Subchapter I deals with energy and minerals (Parts 200–227).`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.ecfr.gov/cgi-bin/text-idx?SID=784ea268c892a669424da1512740a933&mc=true&tpl=/ecfrbrowse/Title30/30tab_02.tpl"
        }}>{`Title 30 `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` governs mineral resources. `}<a parentName="li" {...{
          "href": "http://www.ecfr.gov/cgi-bin/text-idx?SID=050aa7804f2ee861c64ba348d17a79c1&mc=true&tpl=/ecfrbrowse/Title30/30cfrv2_02.tpl#0"
        }}>{`Chapter II `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` deals with the BSEE; `}<a parentName="li" {...{
          "href": "http://www.ecfr.gov/cgi-bin/text-idx?SID=050aa7804f2ee861c64ba348d17a79c1&mc=true&tpl=/ecfrbrowse/Title30/30cfrv2_02.tpl#500"
        }}>{`Chapter V `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` deals with BOEM; `}<a parentName="li" {...{
          "href": "http://www.ecfr.gov/cgi-bin/text-idx?SID=050aa7804f2ee861c64ba348d17a79c1&mc=true&tpl=/ecfrbrowse/Title30/30cfrv3_02.tpl#0"
        }}>{`Chapter VII `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` deals with OSMRE; `}<a parentName="li" {...{
          "href": "http://www.ecfr.gov/cgi-bin/text-idx?SID=050aa7804f2ee861c64ba348d17a79c1&mc=true&tpl=/ecfrbrowse/Title30/30cfrv3_02.tpl#1200"
        }}>{`Chapter XII `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` deals with ONRR.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.ecfr.gov/cgi-bin/text-idx?tpl=/ecfrbrowse/Title43/43tab_02.tpl"
        }}>{`Title 43 `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` in the Code of Federal Regulations governs public lands. Subchapter C focuses on minerals management (Parts 3000–3870).`}</li>
    </ul>
    <p>{`Implementing laws includes complying with the `}<a parentName="p" {...{
        "href": "http://www.gpo.gov/fdsys/pkg/USCODE-2010-title42/pdf/USCODE-2010-title42-chap55-sec4321.pdf"
      }}>{`National Environmental Policy Act (NEPA) of 1969 (PDF) `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{` (42 USC § 4321 et seq.). NEPA is intended to ensure that decision makers and the public have information about the potential impacts to the environment of proposed federal actions and alternatives to those actions. When taking any major action, such as leasing natural resources on federal lands for extraction, federal agencies must prepare an Environmental Assessment and, if applicable, an `}<GlossaryTerm mdxType="GlossaryTerm">{`Environmental Impact Statement`}</GlossaryTerm>{`. The public has legally mandated opportunities to comment on these impact statements.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      